import { useQueryClient } from '@tanstack/react-query';
import { Form, Input, message } from 'antd';
import { useEffect } from 'react';
import tw from 'twin.macro';

import { useEditUser } from '@/hooks/mutations/universal';
import { useMe } from '@/hooks/queries/universal';
import type { EditUserParams } from '@/types/universal';
import queryKeys from '@/utils/queryKeys';

import Submit from './Submit';

const EditUser = () => {
  const queryClient = useQueryClient();
  const [form] = Form.useForm<
    Required<EditUserParams> & { passwordConfirm: string }
  >();
  const getMe = useMe();
  const editUser = useEditUser({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.universal.getMe(),
      });
      message.success('수정 완료되었습니다.');
    },
    onError: () => {
      message.error('수정 실패하였습니다.');
    },
  });

  useEffect(() => {
    if (getMe.data) {
      form.resetFields();
    }
  }, [getMe.data]);

  if (getMe.isLoading || !getMe.data) {
    return null;
  }

  return (
    <div css={tw`w-full max-w-[360px]`}>
      <Form
        form={form}
        requiredMark={false}
        initialValues={{
          phone: getMe.data.phone,
          password: '',
          passwordConfirm: '',
        }}
        onFinish={(values) => {
          editUser.mutate({
            id: getMe.data.id,
            phone: values.phone,
            password: values.password || undefined,
          });
        }}
        layout="vertical"
        validateTrigger={['onChange', 'onBlur']}
      >
        <Form.Item label="아이디">
          <Input size="large" disabled value={getMe?.data?.email} />
        </Form.Item>
        <Form.Item
          label="휴대폰번호"
          name="phone"
          rules={[
            { required: true, message: '휴대폰번호를 입력해주세요' },
            {
              pattern: /^\d{3}-\d{3,4}-\d{4}$/,
              message: '휴대폰번호를 정확히 입력해주세요',
            },
          ]}
        >
          <Input size="large" placeholder="010-1234-1234" />
        </Form.Item>
        <Form.Item
          style={{
            marginBottom: 8,
          }}
          label="비밀번호"
          name="password"
          rules={[
            {
              min: 6,
              message: '6자리 이상 비밀번호를 입력해주세요',
            },
          ]}
        >
          <Input.Password
            size="large"
            placeholder="변경할 비밀번호를 입력해주세요 (6자리 이상)"
            maxLength={100}
          />
        </Form.Item>
        <Form.Item
          name="passwordConfirm"
          dependencies={['password']}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!getFieldValue('password') && !value) {
                  return Promise.resolve();
                }
                if (getFieldValue('password') && !value) {
                  return Promise.reject(
                    new Error('비밀번호 확인을 입력해주세요')
                  );
                }
                if (getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('비밀번호가 일치하지 않습니다')
                );
              },
            }),
          ]}
        >
          <Input.Password
            size="large"
            placeholder="비밀번호를 한번 더 입력해주세요"
            maxLength={100}
          />
        </Form.Item>

        <Form.Item shouldUpdate>
          {() => {
            return <Submit isLoading={editUser.isPending}>수정하기</Submit>;
          }}
        </Form.Item>
      </Form>
    </div>
  );
};

export default EditUser;
