import type {
  Adjustment,
  EditUserParams,
  GetBranchDailyOperationRatesParams,
  GetBranchDailyOperationRatesResponse,
  GetBranchDailySharesParams,
  GetBranchDailySharesResponse,
  GetContractsParams,
  GetContractsResponse,
  GetMyPermissionsResponse,
  LoginParams,
  LoginResponse,
  UnitCompositionRate,
  UnitOperationRate,
  UserResponse,
} from '@/types/universal';
import axios from '@/utils/axios';

export const login = ({
  email,
  password,
}: LoginParams): Promise<LoginResponse> => {
  return axios
    .post('/partner/v1/users/login', {
      email,
      password,
    })
    .then((res) => res.data);
};

export const refreshToken = (refreshToken: string): Promise<LoginResponse> => {
  return axios
    .put(
      '/partner/v1/users/token',
      { refreshToken },
      {
        headers: {
          Authorization: null,
        },
      }
    )
    .then((res) => res.data);
};

export const getMe = (): Promise<UserResponse> => {
  return axios.get('/partner/v1/users/me').then((res) => res.data);
};

export const getMyPermissions = (): Promise<GetMyPermissionsResponse> => {
  return axios.get('/partner/v1/users/permissions').then((res) => res.data);
};

export const getBranchDailyShares = ({
  branchId,
  page,
  size,
  recordedAtAfter,
  recordedAtBefore,
}: GetBranchDailySharesParams): Promise<GetBranchDailySharesResponse> => {
  return axios
    .get(`/partner/v1/stats/units/${branchId}/shares/daily`, {
      params: {
        page,
        size,
        recordedAtAfter,
        recordedAtBefore,
      },
    })
    .then((res) => res.data);
};

export const getBranchDailyOperationRates = ({
  branchId,
  page,
  size,
}: GetBranchDailyOperationRatesParams): Promise<GetBranchDailyOperationRatesResponse> => {
  return axios
    .get(`/partner/v1/stats/units/${branchId}/operation-rates/daily`, {
      params: {
        page,
        size,
      },
    })
    .then((res) => res.data);
};

export const getUnitOperationRates = (
  branchId: number
): Promise<UnitOperationRate[]> => {
  return axios
    .get(`/partner/v1/stats/units/${branchId}/operation-rates`)
    .then((res) => res.data);
};

export const getUnitCompositionRates = (
  branchId: number
): Promise<UnitCompositionRate[]> => {
  return axios
    .get(`/partner/v1/stats/units/${branchId}/composition-rates`)
    .then((res) => res.data);
};

export const getAdjustments = (): Promise<Adjustment[]> => {
  return axios.get(`/partner/v1/branches/adjustments`).then((res) => res.data);
};

export const getContracts = ({
  branchId,
  page,
  size,
  clientType,
  contractStartAtBefore,
  contractStartAtAfter,
  contractEndAtAfter,
  contractEndAtBefore,
  contractStatus,
}: GetContractsParams): Promise<GetContractsResponse> => {
  return axios
    .get(`/partner/v1/contracts/${branchId}/current-contracts`, {
      params: {
        page,
        size,
        clientType,
        contractStartAtAfter,
        contractStartAtBefore,
        contractEndAtAfter,
        contractEndAtBefore,
        contractStatus,
      },
    })
    .then((res) => res.data);
};

export const editUser = ({
  id,
  phone,
  password,
}: EditUserParams): Promise<UserResponse> => {
  return axios
    .patch(`/partner/v1/users/${id}`, { phone, password })
    .then((res) => res.data);
};
