import { Button, Form } from 'antd';
import { useEffect, useState } from 'react';
import tw from 'twin.macro';

interface Props {
  isLoading: boolean;
  children: string;
}

const Submit = ({ isLoading, children }: Props) => {
  const form = Form.useFormInstance();
  const [active, setActive] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setActive(true);
    }, 500);
  });

  const hasError = !!form.getFieldsError().filter(({ errors }) => errors.length)
    .length;
  const isTouchedFields = form.isFieldsTouched(['email', 'password'], true);

  return (
    <Button
      css={tw`mt-[16px]`}
      type="primary"
      htmlType="submit"
      size="large"
      block
      loading={isLoading}
      disabled={!active || hasError || !isTouchedFields}
    >
      {children}
    </Button>
  );
};

export default Submit;
