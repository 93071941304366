import { Button, Form } from 'antd';
import { useEffect, useRef, useState } from 'react';
import tw from 'twin.macro';

interface Props {
  isLoading: boolean;
  children: string;
}

const Submit = ({ isLoading, children }: Props) => {
  const initialValues = useRef<Record<string, unknown>>({});
  const form = Form.useFormInstance();
  const [active, setActive] = useState(false);
  const requireFields = ['phone'];

  useEffect(() => {
    setTimeout(() => {
      setActive(true);
    }, 500);
  });

  useEffect(() => {
    initialValues.current =
      requireFields?.reduce((acc, field) => {
        return { ...acc, [field]: form.getFieldValue(field) };
      }, {}) ?? {};
  }, []);

  const hasError = !!form.getFieldsError().filter(({ errors }) => errors.length)
    .length;
  const phone = form.getFieldValue('phone');
  const isPhoneChanged = !!phone && initialValues.current.phone !== phone;
  const isPasswordChanged =
    !!form.getFieldValue('password') || !!form.getFieldValue('passwordConfirm');

  return (
    <Button
      css={tw`mt-[16px]`}
      type="primary"
      htmlType="submit"
      size="large"
      block
      loading={isLoading}
      disabled={
        !active || (isPasswordChanged ? hasError : !isPhoneChanged || hasError)
      }
    >
      {children}
    </Button>
  );
};

export default Submit;
