import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import * as partnerApi from '@/apis/universal';
import type { CommonUseQueryOptions } from '@/types/app';
import type {
  Adjustment,
  GetBranchDailyOperationRatesParams,
  GetBranchDailyOperationRatesResponse,
  GetBranchDailySharesParams,
  GetBranchDailySharesResponse,
  GetContractsParams,
  GetContractsResponse,
  GetMyPermissionsResponse,
  UnitCompositionRate,
  UnitOperationRate,
  UserResponse,
} from '@/types/universal';
import queryKeys from '@/utils/queryKeys';

export const useMe = (options?: CommonUseQueryOptions<UserResponse>) => {
  const query = useQuery({
    queryKey: queryKeys.universal.getMe(),
    queryFn: () => partnerApi.getMe(),
    ...options,
  });

  return query;
};

export const useMyPermissions = (
  options?: CommonUseQueryOptions<GetMyPermissionsResponse>
) => {
  const query = useQuery({
    queryKey: queryKeys.universal.getMyPermissions(),
    queryFn: () => partnerApi.getMyPermissions(),
    ...options,
  });

  const availableMenuKeys = useMemo(() => {
    return Object.values(query.data?.permissions || {}).flatMap((group) =>
      Object.values(group).flat()
    );
  }, [query.data]);

  return { ...query, availableMenuKeys };
};

export const useBranchDailyShares = (
  params: GetBranchDailySharesParams,
  options?: CommonUseQueryOptions<GetBranchDailySharesResponse>
) => {
  const query = useQuery({
    queryKey: queryKeys.universal.getBranchDailyShares(params),
    queryFn: () => partnerApi.getBranchDailyShares(params),
    ...options,
  });

  return query;
};

export const useBranchDailyOperationRates = (
  params: GetBranchDailyOperationRatesParams,
  options?: CommonUseQueryOptions<GetBranchDailyOperationRatesResponse>
) => {
  const query = useQuery({
    queryKey: queryKeys.universal.getBranchDailyOperationRates(params),
    queryFn: () => partnerApi.getBranchDailyOperationRates(params),
    ...options,
  });

  return query;
};

export const useUnitOperationRates = (
  branchId: number,
  options?: CommonUseQueryOptions<UnitOperationRate[]>
) => {
  const query = useQuery({
    queryKey: queryKeys.universal.getUnitOperationRates(branchId),
    queryFn: () => partnerApi.getUnitOperationRates(branchId),
    ...options,
  });

  return query;
};

export const useUnitCompositionRates = (
  branchId: number,
  options?: CommonUseQueryOptions<UnitCompositionRate[]>
) => {
  const query = useQuery({
    queryKey: queryKeys.universal.getUnitCompositionRates(branchId),
    queryFn: () => partnerApi.getUnitCompositionRates(branchId),
    ...options,
  });

  return query;
};

export const useAdjustments = (
  options?: CommonUseQueryOptions<Adjustment[]>
) => {
  const query = useQuery({
    queryKey: queryKeys.universal.getAdjustments(),
    queryFn: () => partnerApi.getAdjustments(),
    ...options,
  });

  return query;
};

export const useContracts = (
  params: GetContractsParams,
  options?: CommonUseQueryOptions<GetContractsResponse>
) => {
  const query = useQuery({
    queryKey: queryKeys.universal.getContracts(params),
    queryFn: () => partnerApi.getContracts(params),
    ...options,
  });

  return query;
};
