import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ConfigProvider } from 'antd';
import locale from 'antd/locale/ko_KR';
import { isAxiosError } from 'axios';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import { getDefaultStore } from 'jotai';
import { DevTools } from 'jotai-devtools';
import 'jotai-devtools/styles.css';
import { BrowserRouter } from 'react-router';

import { refreshToken } from '@/apis/universal';
import { authAtom } from '@/store/atoms';
import type { ErrorResponse } from '@/types/api';

import Route from './Route';
import './global.scss';

dayjs.locale('ko');

let refreshing = false;
let callbackQueue: Array<() => void> = [];
const handleExpireToken = async (error: Error, callback: () => void) => {
  // 토큰 만료 발생 시 처리 로직
  if (isAxiosError<ErrorResponse>(error)) {
    if (error.response?.data.error === 'EXPIRED-TOKEN') {
      const store = getDefaultStore();
      const auth = store.get(authAtom);

      callbackQueue.push(callback);

      try {
        if (!refreshing) {
          // 토큰 리프레시 api를 중복 호출하지 않기 위해 플래그 처리
          let checkCount = 0;
          const clearCallbackQueue = () => {
            while (callbackQueue.length > 0) {
              const firstCallback = callbackQueue.shift() as () => void;

              firstCallback();
            }
            checkCount += 1;

            if (checkCount === 10) {
              clearInterval(checkIntervalId);
              checkCount = 0;
            }
          };

          refreshing = true;
          const newAuth = await refreshToken(auth.refreshToken);
          store.set(authAtom, newAuth);

          // 일단 한번 콜백큐 비우기 시도
          clearCallbackQueue();
          // 토큰 리프레시 이후 1초간격으로 10번 콜백큐 비우기
          const checkIntervalId = setInterval(clearCallbackQueue, 1000);
          refreshing = false;
        }
      } catch {
        // 리프레시 토큰의 기간도 만료된 경우
        refreshing = false;
        callbackQueue = [];
        store.set(authAtom, {
          accessToken: '',
          refreshToken: '',
        });
      }
    }
  }
};

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error, query) => {
      handleExpireToken(error, () => {
        queryClient.refetchQueries({ queryKey: query.queryKey });
      });
    },
  }),
  mutationCache: new MutationCache({
    onError: (error, variables, context, mutation) => {
      handleExpireToken(error, () => {
        mutation?.options?.mutationFn?.(variables);
      });
    },
  }),
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 5 * 60 * 1000,
    },
    mutations: {
      retry: false,
    },
  },
});

const App = () => {
  return (
    <>
      <DevTools />
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <ConfigProvider
            locale={locale}
            theme={{
              hashed: false,
              token: {
                fontSizeLG: 14,
              },
              components: {
                Timeline: {
                  dotBg: 'rgba(0, 0, 0, 0.15)',
                  dotBorderWidth: 4,
                  tailWidth: 1,
                  itemPaddingBottom: 4,
                },
                Table: {
                  cellPaddingBlock: 12,
                },
                Form: {
                  itemMarginBottom: 16,
                  verticalLabelPadding: '4px 0',
                },
                Button: {
                  fontSizeLG: 16,
                },
              },
            }}
          >
            <Route />
          </ConfigProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </>
  );
};

export default App;
