import { useMutation } from '@tanstack/react-query';

import * as partnerApi from '@/apis/universal';
import type { CommonUseMutationOptions } from '@/types/app';
import type {
  EditUserParams,
  LoginParams,
  LoginResponse,
  UserResponse,
} from '@/types/universal';

export const useLogin = (
  options?: CommonUseMutationOptions<LoginParams, LoginResponse>
) => {
  const mutation = useMutation({
    mutationFn: (params) => partnerApi.login(params),
    ...options,
  });

  return mutation;
};

export const useEditUser = (
  options?: CommonUseMutationOptions<EditUserParams, UserResponse>
) => {
  const mutation = useMutation({
    mutationFn: (params) => partnerApi.editUser(params),
    ...options,
  });

  return mutation;
};
