import { InfoCircleOutlined } from '@ant-design/icons';
import { Form, Input, Typography, message } from 'antd';
import { useSetAtom } from 'jotai';
import tw from 'twin.macro';

import { PartnersLogo } from '@/assets/svg';
import { useLogin } from '@/hooks/mutations/universal';
import { authAtom } from '@/store/atoms';
import type { LoginParams } from '@/types/universal';

import Submit from './Submit';

const Login = () => {
  const setAuth = useSetAtom(authAtom);
  const [form] = Form.useForm<LoginParams>();
  const login = useLogin({
    onSuccess: ({ accessToken, refreshToken }) => {
      setAuth({
        accessToken,
        refreshToken,
      });
    },
    onError: (err) => {
      if (err.response) {
        message.error(err.response.data.description);
      }
    },
  });

  return (
    <div css={tw`flex flex-col items-center w-full max-w-[360px]`}>
      <div
        css={[
          tw`flex flex-col items-center gap-[32px] p-[32px 24px 16px] w-full rounded-[16px] bg-white`,
          tw`[& > svg]:(w-[130px] h-[20px])`,
        ]}
      >
        <PartnersLogo />

        <Form
          form={form}
          requiredMark={false}
          initialValues={{
            email: '',
            password: '',
          }}
          onFinish={(values) => {
            login.mutate({
              email: values.email,
              password: values.password,
            });
          }}
          css={tw`w-full`}
          layout="vertical"
          validateTrigger={['onChange', 'onBlur']}
        >
          <Form.Item
            label="아이디"
            name="email"
            rules={[
              { required: true, message: '이메일을 입력해주세요' },
              {
                type: 'email',
                message: '메일 주소를 정확히 입력해주세요',
              },
            ]}
          >
            <Input size="large" placeholder="@를 포함한 이메일 주소 입력" />
          </Form.Item>

          <Form.Item
            label="비밀번호"
            name="password"
            rules={[
              { required: true, message: '비밀번호를 입력해주세요' },
              {
                min: 6,
                message: '6자리 이상 비밀번호를 입력해주세요',
              },
            ]}
          >
            <Input.Password
              size="large"
              placeholder="6자리 이상 입력"
              maxLength={100}
            />
          </Form.Item>

          <Form.Item shouldUpdate>
            {() => {
              return <Submit isLoading={login.isPending}>로그인하기</Submit>;
            }}
          </Form.Item>
        </Form>
      </div>

      <Typography.Text
        css={tw`flex flex-row items-center mt-[24px]`}
        type="secondary"
      >
        <InfoCircleOutlined css={tw`mr-[8px]`} size={16} />
        문의사항은 담당자에게 연락해주세요
      </Typography.Text>
    </div>
  );
};

export default Login;
